class GreenAudioPlayer {
    constructor(player, options) {
        this.audioPlayer = typeof player === 'string' ? document.querySelector(player) : player;
        const opts = options || {};

        const audioElement = this.audioPlayer.innerHTML;
        this.audioPlayer.classList.add('green-audio-player');
        this.audioPlayer.innerHTML = GreenAudioPlayer.getTemplate() + audioElement;

        this.player = this.audioPlayer.querySelector('audio');
        this.loading = this.audioPlayer.querySelector('.controls-ui__loading');
        this.playPauseBtn = this.audioPlayer.querySelector('.controls__play-pause-btn');
        this.sliders = this.audioPlayer.querySelectorAll('.controls__slider');
        this.progress = this.audioPlayer.querySelector('.controls__progress');
        this.currentTime = this.audioPlayer.querySelector('.controls-ui__current-time');
        this.totalTime = this.audioPlayer.querySelector('.controls-ui__total-time');
        this.volumeControls = this.audioPlayer.querySelector('.controls__volume');
        this.volumeBtn = this.audioPlayer.querySelector('.controls__volume-btn');
        this.volumeProgress = this.volumeControls.querySelector('.controls-ui__volume-progress');
        this.download = this.audioPlayer.querySelector('.controls__download');
        this.downloadLink = this.audioPlayer.querySelector('.controls__download-link');
        this.message = this.audioPlayer.querySelectorAll('.controls-ui__message');
        this.currentlyDragged = null;
        this.stopOthersOnPlay = opts.stopOthersOnPlay || false;
        this.enableKeystrokes = opts.enableKeystrokes || false;
        this.showTooltips = opts.showTooltips || false;

        const self = this;

        this.labels = {
            pause: 'Pause',
            play: 'Play',
            download: 'Download',
        };

        if (!this.enableKeystrokes) {
            for (let i = 0; i < this.message.length; i++) {
                this.message[i].outerHTML = '';
            }
        } else {
            window.addEventListener('keydown', this.pressKb.bind(self), false);
            window.addEventListener('keyup', this.unPressKb.bind(self), false);
            this.playPauseBtn.setAttribute('tabindex', 0);
            this.sliders[0].setAttribute('tabindex', 0);
            this.volumeControls.setAttribute('tabindex', 0);
            this.downloadLink.setAttribute('tabindex', 0);
        }

        if (this.showTooltips) {
            this.playPauseBtn.setAttribute('title', this.labels.play);
            this.downloadLink.setAttribute('title', this.labels.download);
        }

        if (opts.outlineControls || false) {
            this.audioPlayer.classList.add('player-accessible');
        }

        if (opts.showVolumeButton || false) {
            this.showVolumeBtn();
        }

        if (opts.showDownloadButton || false) {
            this.showDownload();
        }

        this.initEvents();
        this.directionAware();
        this.overcomeIosLimitations();

        if ('autoplay' in this.player.attributes) {
            const promise = this.player.play();
            if (promise !== undefined) {
                promise.then(() => {
                    self.playPauseBtn.classList.add('play');
                    self.playPauseBtn.setAttribute('aria-label', self.labels.pause);
                    self.hasSetAttribute(self.playPauseBtn, 'title', self.labels.pause);
                }).catch(() => {
                    // eslint-disable-next-line no-console
                    console.error('Green Audio Player Error: Autoplay has been prevented, because it is not allowed by this browser.');
                });
            }
        }
        if ('preload' in this.player.attributes && this.player.attributes.preload.value === 'none')
            this.hideLoadingIndicator();
    }

    static init(options) {
        const players = document.querySelectorAll(options.selector);

        players.forEach((player) => {
            /* eslint-disable no-new */
            new GreenAudioPlayer(player, options);
        });
    }

    static getTemplate() {
        return '<div>' +
                    '<div class="controls-ui__loading">' +
                        '<div></div>' +
                    '</div>' +

                    '<div class="controls__play-pause-btn hidden" aria-label="Play" role="button"></div>' +
                '</div>' +

                '<div>' +
                    '<span class="controls-ui__current-time" aria-live="off" role="timer">00:00</span>' +
                    '<div class="controls__slider" data-direction="horizontal">' +
                        '<div class="controls__progress" aria-label="Time Slider" aria-valuemin="0" aria-valuemax="100" aria-valuenow="0" role="slider">' +
                            '<div data-method="rewind"></div>' +
                        '</div>' +
                    '</div>' +
                    '<span class="controls-ui__total-time">00:00</span>' +
                '</div>' +

                '<div class="controls__volume hidden">' +
                    '<div class="controls__volume-btn" aria-label="Open Volume Controls" role="button" data-volume="max">' +
                        '<span class="controls-ui__message">Press Enter or Space to show volume slider.</span>' +
                    '</div>' +
                    '<div class="controls__volume-slider">' +
                        '<div class="controls__slider" data-direction="vertical">' +
                            '<div class="controls-ui__volume-progress" aria-label="Volume Slider" aria-valuemin="0" aria-valuemax="100" aria-valuenow="100" role="slider">' +
                                '<div data-method="changeVolume"></div>' +
                            '</div>' +
                            '<span class="controls-ui__message">Use Up/Down Arrow keys to increase or decrease volume.</span>' +
                        '</div>' +
                    '</div>' +
                '</div>' +

                '<div class="controls__download hidden">' +
                    '<a class="controls__download-link" href="" download="" aria-label="Download" role="button"></a>' +
                '</div>';
    }

    initEvents() {
        const self = this;

        function eventHandler(event) {
            const touchEvent = event.type === 'touchstart';

            if (self.isDraggable(event.target)) {
                touchEvent && event.preventDefault();

                touchEvent ? [self.currentlyDragged] = event.targetTouches : self.currentlyDragged = event.target;
                const handleMethod = touchEvent ? self.currentlyDragged.target.dataset.method : self.currentlyDragged.dataset.method;
                const listener = self[handleMethod].bind(self);
                window.addEventListener(touchEvent ? 'touchmove' : 'mousemove', listener, false);
                if (self.currentlyDragged.parentElement.parentElement === self.sliders[0]) {
                    self.paused = self.player.paused;

                    if (self.paused === false) {
                        if (self.togglePlayTimeout)
                            clearTimeout(self.togglePlayTimeout);

                        self.togglePlayTimeout = setTimeout(() => { self.togglePlay(); }, 300);
                    }
                }
                window.addEventListener(touchEvent ? 'touchend' : 'mouseup', () => {
                    if (self.currentlyDragged !== false
                        && self.currentlyDragged.parentElement.parentElement === self.sliders[0]
                        && self.paused !== self.player.paused) {
                        if (self.togglePlayTimeout)
                            clearTimeout(self.togglePlayTimeout);

                        self.togglePlayTimeout = setTimeout(() => { self.togglePlay(); }, 300);
                    }

                    self.currentlyDragged = false;
                    window.removeEventListener(touchEvent ? 'touchmove' : 'mousemove', listener, false);
                }, false);
            }
        }

        self.audioPlayer.addEventListener('mousedown', (event) => { eventHandler(event); });

        // for mobile touches
        self.audioPlayer.addEventListener('touchstart', (event) => { eventHandler(event) });

        this.playPauseBtn.addEventListener('click', this.togglePlay.bind(self));
        this.player.addEventListener('timeupdate', this.updateProgress.bind(self));
        this.player.addEventListener('volumechange', this.updateVolume.bind(self));
        this.player.volume = 1;
        this.lastVolume = this.player.volume;
        this.player.addEventListener('loadedmetadata', () => {
            this.hideLoadingIndicator();
            self.totalTime.textContent = GreenAudioPlayer.formatTime(self.player.duration);
        });
        this.player.addEventListener('seeking', this.showLoadingIndicator.bind(self));
        this.player.addEventListener('seeked', this.hideLoadingIndicator.bind(self));
        this.player.addEventListener('canplaythrough', this.hideLoadingIndicator.bind(self));
        this.player.addEventListener('ended', () => {
            GreenAudioPlayer.pausePlayer(self.player, 'ended');
            self.player.currentTime = 0;
            self.playPauseBtn.setAttribute('aria-label', self.labels.play);
            self.hasSetAttribute(self.playPauseBtn, 'title', self.labels.play);
        });
        this.player.addEventListener('waiting', this.showLoadingIndicator.bind(self));

        this.volumeBtn.addEventListener('click', this.toggleVolume.bind(self));
        window.addEventListener('resize', self.directionAware.bind(self));
        window.addEventListener('scroll', self.directionAware.bind(self));

        for (let i = 0; i < this.sliders.length; i++) {
            const pin = this.sliders[i].querySelector('[data-method]');
            this.sliders[i].addEventListener('click', self[pin.dataset.method].bind(self));
        }

        this.downloadLink.addEventListener('click', this.downloadAudio.bind(self));
    }

    overcomeIosLimitations() {
        let self = this;
        if (IS_MOBILE) {
            // iOS does not let "volume" property to be set programmatically
            this.volumeControls.classList.add('hidden');
        }
    }

    isDraggable(el) {
        return ('method' in el.dataset);
    }

    inRange(event) {
        const touch = ('touches' in event); // instanceof TouchEvent may also be used
        const rangeBox = this.getRangeBox(event);
        const sliderPositionAndDimensions = rangeBox.getBoundingClientRect();
        const { dataset: { direction } } = rangeBox;
        let min = null;
        let max = null;

        if (direction === 'horizontal') {
            min = sliderPositionAndDimensions.x;
            max = Math.ceil(min) + sliderPositionAndDimensions.width;
            const clientX = touch ? event.touches[0].clientX : event.clientX;
            if (clientX < min || clientX > max) return false;
        } else {
            min = sliderPositionAndDimensions.top;
            max = Math.ceil(min) + sliderPositionAndDimensions.height;
            const clientY = touch ? event.touches[0].clientY : event.clientY;
            if (clientY < min || clientY > max) return false;
        }
        return true;
    }

    updateProgress() {
        const current = this.player.currentTime;
        const percent = (current / this.player.duration) * 100;
        this.progress.setAttribute('aria-valuenow', percent);
        this.progress.style.width = `${percent}%`;

        this.currentTime.textContent = GreenAudioPlayer.formatTime(current);
    }

    updateVolume() {
        this.volumeProgress.setAttribute('aria-valuenow', this.player.volume * 100);
        this.volumeProgress.style.height = `${this.player.volume * 100}%`;
        if (this.player.volume >= 0.5)
            this.volumeBtn.dataset.volume = 'max';
        else if (this.player.volume < 0.5 && this.player.volume > 0.05)
            this.volumeBtn.dataset.volume = 'mid';
        else if (this.player.volume <= 0.05)
            this.volumeBtn.dataset.volume = 'min';
    }

    getRangeBox(event) {
        let rangeBox = event.target;
        const el = this.currentlyDragged;
        if (event.type === 'click' && this.isDraggable(event.target)) {
            rangeBox = event.target.parentElement.parentElement;
        }
        if (event.type === 'mousemove') {
            rangeBox = el.parentElement.parentElement;
        }
        if (event.type === 'touchmove') {
            rangeBox = el.target.parentElement.parentElement;
        }
        return rangeBox;
    }


    getCoefficient(event) {
        const touch = ('touches' in event); // instanceof TouchEvent may also be used

        const slider = this.getRangeBox(event);
        const sliderPositionAndDimensions = slider.getBoundingClientRect();
        let K = 0;
        if (slider.dataset.direction === 'horizontal') {
            // if event is touch
            const clientX = touch ? event.touches[0].clientX : event.clientX;
            const offsetX = clientX - sliderPositionAndDimensions.left;
            const { width } = sliderPositionAndDimensions;
            K = offsetX / width;
        } else if (slider.dataset.direction === 'vertical') {
            const { height } = sliderPositionAndDimensions;
            const clientY = touch ? event.touches[0].clientY : event.clientY;
            const offsetY = clientY - sliderPositionAndDimensions.top;
            K = 1 - offsetY / height;
        }
        return K;
    }

    rewind(event) {
        if (this.player.seekable && this.player.seekable.length) { // no seek if not (pre)loaded
            if (this.inRange(event)) {
                this.player.currentTime = this.player.duration * this.getCoefficient(event);
            }
        }
    }

    showVolumeBtn() {
        IS_DESKTOP && this.volumeControls.classList.remove('hidden');
    }

    changeVolume(event) {
        if (this.inRange(event)) {
            this.player.volume = Math.round(this.getCoefficient(event) * 50) / 50;
            this.lastVolume = this.player.volume;
        }
    }

    static formatTime(time) {
        const min = Math.floor(time / 60);
        const sec = Math.floor(time % 60);
        return `${(min < 10) ? `0${min}` : min}:${(sec < 10) ? `0${sec}` : sec}`;
    }

    preloadNone() {
        if (!this.player.duration)
            this.showLoadingIndicator();
    }

    togglePlay() {
        this.preloadNone();
        if (this.player.paused) {
            if (this.stopOthersOnPlay) {
                GreenAudioPlayer.stopOtherPlayers();
            }
            GreenAudioPlayer.playPlayer(this.player);
            this.playPauseBtn.setAttribute('aria-label', this.labels.pause);
            this.hasSetAttribute(this.playPauseBtn, 'title', this.labels.pause);
        } else {
            GreenAudioPlayer.pausePlayer(this.player, 'toggle');
            this.playPauseBtn.setAttribute('aria-label', this.labels.play);
            this.hasSetAttribute(this.playPauseBtn, 'title', this.labels.play);
        }
    }

    hasSetAttribute(el, a, v) {
        if (this.showTooltips) {
            if (el.hasAttribute(a)) {
                el.setAttribute(a, v);
            }
        }
    }

    setCurrentTime(time) {
        const pos = this.player.currentTime;
        const end = Math.floor(this.player.duration);
        if (pos + time < 0 && pos === 0) {
            this.player.currentTime = this.player.currentTime;
        } else if (pos + time < 0) {
            this.player.currentTime = 0;
        } else if (pos + time > end) {
            this.player.currentTime = end;
        } else {
            this.player.currentTime += time;
        }
    }

    toggleVolume() {
        if (this.player.volume) {
            this.lastVolume = this.player.volume;
            this.player.volume = 0;
        }
        else
            this.player.volume = this.lastVolume || 1;

        this.updateVolume();
    }

    setVolume(volume) {
        if (IS_MOBILE) return;

        const vol = this.player.volume;
        if (vol + volume >= 0 && vol + volume < 1) {
            this.player.volume += volume;
        } else if (vol + volume <= 0) {
            this.player.volume = 0;
        } else {
            this.player.volume = 1;
        }
        this.lastVolume = this.player.volume;
    }

    unPressKb(event) {
        const evt = event || window.event;
        if (this.seeking && (evt.keyCode === 37 || evt.keyCode === 39)) {
            this.togglePlay();
            this.seeking = false;
        }
    }

    pressKb(event) {
        const evt = event || window.event;
        switch (evt.keyCode) {
        case 13: // Enter
        case 32: // Spacebar
            if (document.activeElement === this.playPauseBtn) {
                this.togglePlay();
            } else if (document.activeElement === this.volumeControls) {
                this.toggleVolume();
            } else if (document.activeElement === this.downloadLink) {
                this.downloadAudio();
            }
            break;
        case 37: case 39: // horizontal Arrows
            if (document.activeElement === this.sliders[0]) {
                evt.preventDefault();
                if (evt.keyCode === 37) {
                    this.setCurrentTime(-5);
                } else {
                    this.setCurrentTime(+5);
                }
                if (!this.player.paused && this.player.seeking) {
                    this.togglePlay();
                    this.seeking = true;
                }
            }
            break;
        case 38: case 40: // vertical Arrows
            if (document.activeElement === this.volumeControls) {
                evt.preventDefault();
                if (evt.keyCode === 38) {
                    this.setVolume(0.05);
                } else {
                    this.setVolume(-0.05);
                }
            }
            break;
        default: break;
        }
    }

    static playPlayer(player) {
        const playPauseButton = player.parentElement.querySelector('.controls__play-pause-btn');
        playPauseButton.classList.add('play');
        player.play();
    }

    static pausePlayer(player) {
        const playPauseButton = player.parentElement.querySelector('.controls__play-pause-btn');
        playPauseButton.classList.remove('play');
        player.pause();
    }

    static stopOtherPlayers() {
        const players = document.querySelectorAll('.green-audio-player audio');

        for (let i = 0; i < players.length; i++) {
            GreenAudioPlayer.pausePlayer(players[i]);
        }
    }

    showLoadingIndicator() {
        if (this.loading.classList.contains('hidden')) {
            this.loading.classList.remove('hidden');
            this.playPauseBtn.classList.add('hidden');
        }
    }

    hideLoadingIndicator() {
        let self = this;

        if (self.hideLoadingTimeout)
            clearTimeout(self.hideLoadingTimeout);

        self.hideLoadingTimeout = setTimeout(() => {
            self.loading.classList.add('hidden');
            self.playPauseBtn.classList.remove('hidden');
        }, 300);
    }

    showDownload() {
        this.download.classList.remove('hidden');
    }

    downloadAudio() {
        const src = this.player.currentSrc;
        const name = src.split('/').reverse()[0];

        this.downloadLink.setAttribute('href', src);
        this.downloadLink.setAttribute('download', name);
    }

    directionAware() {
        this.volumeControls.classList.remove('top', 'middle', 'bottom');

        if (window.innerHeight < 250) {
            this.volumeControls.classList.add('middle');
        } else if (this.audioPlayer.getBoundingClientRect().top < 180) {
            this.volumeControls.classList.add('bottom');
        } else {
            this.volumeControls.classList.add('top');
        }
    }
}